import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const SecondPage = () => {

  const list = useStaticQuery(graphql`
  query list {
    directus {
      Segments {
        dropdown
        rel_segment_articles {
          article_name
          article_name_slug
          rel_article_segment {
            slug
          }
        }
        segment_name
        slug
      }
    }
  }
  `)

  // console.log(list.directus.Segments)

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Layout>
      <Seo title="Page two" />
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2</p>
      <p>Test</p>
      <Box>
      {list.directus.Segments.map((segment) => (
            segment.dropdown ? 
              <Box>
                <p>{segment.segment_name}</p>
                <Box>
                {segment.rel_segment_articles.map((article) => (
                      <Typography>{article.article_name} </Typography>
                    ))}
                </Box>
              </Box>
            :
              <p>{segment.segment_name} </p>
                
          ))}
      </Box>

      <Box
      sx={{ width: 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List >
        {list.directus.Segments.map((segment) => (
            segment.dropdown ? 
              // <MenuDropdown 
              //   categories={segment.segment_articles} 
              //   title={segment.segment_name}/>
              <Box>
                <ListItemButton onClick={handleClick}>
                  <ListItemText primary={segment.segment_name} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {segment.rel_segment_articles.map((article) => {
                      <ListItemButton sx={{ pl: 4 }}
                        key={article.article_name_slug}
                        component={Link}
                        to={'/' + segment.slug + '/' + article.article_name_slug}
                      >
                        <ListItemText primary={article.article_name} />
                      </ListItemButton>
                    })}
                    <ListItemButton sx={{ pl: 4 }} component={Link} to={'/'}>
            
                      <ListItemText primary="Starred2" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </Box> 
            :
              <ListItem 
                key={segment.segment_name} 
                component={Link}
                to={'/' + segment.slug}
                disablePadding
                >
                <ListItemButton>
                  <ListItemText primary={segment.segment_name} />
                </ListItemButton>
              </ListItem>
          ))}
        </List>
    </Box>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )

}

export default SecondPage
